import React from 'react'
import { Link } from 'gatsby'
import shop from '../../assets/images/HOME_SHOP.png'
import eventHire from '../../assets/images/HOME_Event hire.png'
import contactUs from '../../assets/images/HOME_Contact us.png'
import homeOurStory from '../../assets/images/HOME_our_story.png'
import Panda from '../../assets/images/Panada.png'

const Home = () => {
  return (
    <>
      <div className='fluid TopBannerbgimage'>
        <div className='container'>
          <div className='row text-center px-7 d-flex'>
            <div className='col-md-8 col-sm-12'>
              <div
                className='text-center dialogbgimage d-flex
                  align-content-center
                  align-items-center
                  flex-direction-column
                  justify-content-center'
              >
                <div className='d-sm-none mobileViewNegTxtMargin'>
                  <h1 className='card-title font-secondary'>
                    LARGE RANGE <br />
                    OF SPECIALTY <br />
                    COFFEES
                  </h1>
                  <p>
                    WERE MAKING WAVES IN LONDONS THRIVING <br />
                    COFFEE AND TEA COMMUNITY
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 pandaImage'>
              <img src={Panda} alt='Panada' className='img-fluid ' />
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='header-m7 justify-content-center d-flex'>
          <div className=' position-relative row col-sm-12 col-md-10 d-flex justify-content-center'>
            <div className='bg-light-green our-story'>
              <div className='row '>
                <div className='col-sm-12 col-md-5 col-lg-3'>
                  <img
                    src={homeOurStory}
                    alt='Shop'
                    className='img-fluid position-absolute top-50 translate-middle-y start-25  our-story-image'
                  />
                </div>

                <div className='col-sm-12 col-md-7 col-lg-9'>
                  <div className='card p-3 '>
                    <div className='card-body '>
                      <h1 className='card-title font-secondary '>OUR STORY</h1>
                      <p className='card-text fs-5'>
                        We are panda cup - an independent artisan cafe teas and
                        bubble bar in London.
                      </p>
                      <Link to='our-story' className='btn btn-outline-dark '>
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className=' mb-4 mt-7'>
          <div className='row'>
            <div className='col-sm-4 col-xs-4 gy-3 d-flex justify-content-center'>
              <div className='position-relative'>
                <img src={shop} alt='Shop' className='img-fluid' />
                <div className='position-absolute bottom-0 end-0 fs-3 text-white font-secondary pe-2'>
                  <div to='shop' className='text-decoration-none text-white'>
                    SHOP COMING SOON
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xs-4 gy-3 d-flex justify-content-center'>
              <div className='position-relative'>
                <img src={eventHire} alt='EVENT HIRE' className=' img-fluid' />
                <div className='position-absolute bottom-0 end-0 fs-1 text-white font-secondary pe-2'>
                  <Link
                    to='event-hire'
                    className='text-decoration-none text-white'
                  >
                    EVENT HIRE
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xs-4 gy-3 d-flex justify-content-center'>
              <div className='position-relative'>
                <img src={contactUs} alt='CONTACT US' className=' img-fluid' />
                <div className='position-absolute bottom-0 end-0 h1 fs-1 font-secondary pe-2'>
                  <Link
                    to='contact-us'
                    className='text-decoration-none text-white'
                  >
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='p-5 bg-yellow'>
        <div className='container px-7'>
          <div className='text-center fs-1 h1 font-secondary'>
            WHAT OUR CUSTOMER SAY
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-4 col-xs-4 text-center  gy-3'>
                <h4>Maria Marwa</h4>
                <p>
                  <a
                    href='https://g.co/kgs/1cj6iH'
                    className='text-decoration-none'
                    target='_blank'
                    rel='noreferrer'
                    title='the best tasting coffee'
                  >
                    This is easily the best tasting coffee in London and the
                    staff are beyond lovely! Couldn’t recommend this place more!
                  </a>
                </p>
              </div>
              <div className='col-sm-4 col-xs-4 text-center gy-3'>
                <h4>Estrella Fádhia Sanz Da Silva</h4>
                <p>
                  <a
                    href='https://g.co/kgs/cPEyvY'
                    className='text-decoration-none'
                    target='_blank'
                    rel='noreferrer'
                    title='Súper fan of the Bubble tea'
                  >
                    Súper fan of the Bubble tea totally in love. They are
                    amazing
                  </a>
                </p>
              </div>
              <div className='col-sm-4 col-xs-4 text-center  gy-3'>
                <h4>Ben Haynes</h4>
                <p>
                  <a
                    href='https://g.co/kgs/9qsH1p'
                    className='text-decoration-none'
                    target='_blank'
                    rel='noreferrer'
                    title='Panda cup coffee is unreal'
                  >
                    Panda cup coffee is unreal to be honest with ya. The way
                    they blend that milk and bean, it’s like nothing I’ve ever
                    seen.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
