import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Home from '../components/Home/home'

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export default IndexPage

export const Head = () => <SEO />
